<template>
  <div>
    <header>
      <div class="block">
        <el-carousel
            trigger="click"
            height="560px"
            autoplay
        >
          <el-carousel-item>
            <img
                src="../assets/banner-3.png"
                alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
                src="../assets/banner-pic-erp.png"
                alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
                src="../assets/2.png"
                alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
                src="../assets/3.png"
                alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
                src="../assets/4.png"
                alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </header>
    <main>
<!--      <img src="../assets/Snipaste_2024-01-08_16-01-48.png" alt=""/>
      <img src="../assets/Snipaste_2024-01-08_16-35-39.png" alt=""/>-->
      <h1>功能简介</h1>

      <div class="function-intro">
        <div class="function-intro-item">
          <router-link to="/fi/erp-sass">
            <img src="../assets/home/南棠erpsass版.png" alt="南棠erpsass版"/>
          </router-link>
        </div>
<!--        <div class="function-intro-item">
          <router-link to="/fi/erp-bs">
            <img src="../assets/home/南棠erp部署版.png" alt="南棠erp部署版"/>
          </router-link>
        </div>-->
        <div class="function-intro-item">
          <router-link to="/fi/erp-3">
            <img src="../assets/home/南棠erp3.0.png" alt="南棠erp3.0"/>
          </router-link>
        </div>
        <div class="function-intro-item">
          <router-link to="/fi/listing">
            <img src="../assets/home/南棠listing.png" alt="南棠listing"/>
          </router-link>
        </div>
        <div class="function-intro-item">
          <router-link to="/fi/wms">
            <img src="../assets/home/wms仓储系统.png" alt="wms仓储系统"/>
          </router-link>
        </div>
<!--        <div class="function-intro-item">
          <img src="../assets/home/南棠物流平台.png" alt="南棠物流平台"/>
        </div>-->
<!--        <div class="function-intro-item">
          <img src="../assets/home/供应链分销.png" alt="供应链分销"/>
        </div>-->
<!--        <div class="function-intro-item">
          <img src="../assets/home/南棠开放平台.png" alt="南棠开放平台"/>
        </div>-->

      </div>

      <img
          src="../assets/Snipaste_2024-01-08_16-45-30.png"
          alt=""
      />
      <img
          src="../assets/Snipaste_2024-01-08_16-59-08.png"
          alt=""
      />
      <img
          src="../assets/Snipaste_2024-01-08_17-01-11.png"
          alt=""
      />

      <Footer/>
    </main>
    <footer>
    </footer>

  </div>
</template>

<script>
import Footer from "../components/footer/footer"
export default {
  props: {},
  components: {
    Footer
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    erpSass() {

    }
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  beforeCreate() {
  },
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
  },
};
</script>

<style lang="scss" scoped>
header {
  height: 560px;
  width: 100%;
}

main {
  padding: 0px;
  height: 1000px;
  width: 100%;

  img {
    margin-bottom: -4px;
  }
}

.function-intro {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 50px 50px;
  overflow: hidden;
}

.function-intro-item {
  flex-basis: 25%;
  padding: 50px;
}

.function-intro-item:hover {
  cursor: pointer;
}

.block img {
  width: 100%;
}

.record-keeping {
  width: 100%;
  height: 100px;
  background-color: #22243A;
}

.record-keeping a {
  color:#91929D
}
</style>
